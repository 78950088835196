export interface IBetaMessage {
  id?: string
  emailHtml: string
  fromTitle: string
  subject: string
  publishDate: Date
  sendThresholdMilliseconds: number
  name: string
  designId: string
}
