import { IConversationVM } from '@engineering11/messaging-web'

export interface IApplicationConversationMetadata {
  jobPostName: string
  companyName: string
  jobPostId: string
  jobPostApplicationId: string
  candidateId: string
  companyId: string
  companyLogoUrl?: string | null
}

export type IApplicationConversationCreateMetadata = Pick<IApplicationConversationMetadata, 'jobPostId' | 'jobPostApplicationId' | 'candidateId'>

export type IApplicationConversationVM = IConversationVM<IApplicationConversationMetadata>

export type IEmployerConversationVM = IConversationVM<IApplicationConversationMetadata>
