import { Component, EventEmitter, Input, Output } from '@angular/core'

import { IWorkHistory } from './../../model/interfaces'

@Component({
  selector: 'ui-work-history-single-card-horizontal',
  template: `
    <div class="item-card horizontal" (click)="viewDetail.emit(workHistory)">
      <div class="header">
        <h3 class="e11-text-white e11-mb-2">{{ workHistory.companyName }}</h3>
        <div class="e11-text-white/70">{{ workHistory.jobTitle }}</div>
        <date-display-start-end class="dates e11-text-sm e11-text-white/70" [item]="workHistory"></date-display-start-end>
      </div>
      <div class="body">
        <!-- <div></div>
        <div class="spacer-2rem"></div> -->
        <div class="text-grey">
          <div class="title bold">{{ 'Description' | translate }}</div>
          <div class="description" [innerHTML]="workHistory.jobDescription"></div>
          <!-- <div *ngIf="workHistoryItem.jobDescription" [ngxSummernoteView]="workHistoryItem?.jobDescription || ''"></div> -->
        </div>
      </div>
    </div>
  `,
})
export class UiWorkHistorySingleCardHorizontalComponent {
  @Input()
  workHistory!: IWorkHistory

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()
}
